import { Controller } from "stimulus"

const DEFAULT_CLASS_NAME = "is--disabled"

export default class extends Controller {
  static values = {
    target: String,
    className: String
  }

  toggleTargets() {
    if (!this.targetNodes) { return }

    this.targetNodes.forEach(targetNode => targetNode.classList.toggle(this.className))
  }

  get targetNodes() {
    if (!this.targetValue) { return [] }

    return document.querySelectorAll(this.targetValue)
  }

  get className() {
    if (!this.classNameValue) { return DEFAULT_CLASS_NAME }

    return this.classNameValue
  }
}
