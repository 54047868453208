import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "role",
    "categories",
    "noteSections",
    "organizations"
  ]

  static values = {
    allowCategoriesAndOrganizationsForRoles: Array,
    allowNoteSectionsForRoles: Array
  }

  // lifecycle
  connect() {
    this.updateUi()
  }

  // events
  onChange(event) {
    this.updateUi()
  }

  // internal
  updateUi() {
    if (!this.hasRoleTarget) { return }

    const role = this.roleTarget.value
    let isVisible = false

    // Categories and Organizations
    isVisible = this.allowCategoriesAndOrganizationsForRolesValue.includes(role)
    this.categoryRelatedTargets.forEach(e => e.classList.toggle("d-none", !isVisible))

    // Note sections
    isVisible = this.allowNoteSectionsForRolesValue.includes(role)
    this.noteSectionRelatedTargets.forEach(e => e.classList.toggle("d-none", !isVisible))
  }

  get categoryRelatedTargets() {
    // Use plural to avoid error if target is missing
    return [...this.categoriesTargets, ...this.organizationsTargets]
  }

  get noteSectionRelatedTargets() {
    return [...this.noteSectionsTargets]
  }
}
