import { Controller } from "stimulus"
import { NOTE_STATUS_MAP as NOTE_STATUS } from "../config/note_statuses"
import { hideElement, showElement } from "../src/dom_helper"

export default class extends Controller {
  static targets = [
    "hiddenInput",
    "immediateClosingContainer",
    "markForClosingContainer",
    "select"
  ]

  connect() { this.initialStatus = this.currentValue }

  // Event handlers

  handleChange() {
    this.hideConfirmationBoxes()

    // Minimal sanity check. the user should not be able to select disabled options in the first place, but let's make sure. THe backend will properly check this anyways.
    try { if (this.selectTarget.selectedOptions[0]?.disabled) { return } } catch(_e) { return }

    // Ideally we'd want some kind of state machine here, but the list of available state changes is small enough to just build it ourselves.
    switch (this.currentValue) {
      case NOTE_STATUS.PROCESSED:
        if (this.initialStatus === NOTE_STATUS.SUBMITTED) { this.acceptStatusChange() }
        if (this.initialStatus === NOTE_STATUS.PROCESSING_COMPLETED) { this.acceptStatusChange() }
        break

      case NOTE_STATUS.PROCESSING_COMPLETED:
        if (this.initialStatus === NOTE_STATUS.SUBMITTED) { this.acceptStatusChange() }
        if (this.initialStatus === NOTE_STATUS.PROCESSED) { this.acceptStatusChange() }
        break

      case NOTE_STATUS.MARKED_FOR_CLOSING:
        if (this.initialStatus === NOTE_STATUS.SUBMITTED) { this.acceptStatusChange() }
        if (this.initialStatus === NOTE_STATUS.PROCESSED) { this.acceptStatusChange() }
        if (this.initialStatus === NOTE_STATUS.PROCESSING_COMPLETED) { this.acceptStatusChange() }
        break

      case NOTE_STATUS.CLOSED:
        if (this.initialStatus === NOTE_STATUS.MARKED_FOR_CLOSING) { this.acceptStatusChange() }
        // If the Note requires a confirmation but is not currently waiting for one, we can't close it right now.
        if (this.isUsingClosingConfirmation()) { return }

        if (this.initialStatus === NOTE_STATUS.SUBMITTED) { this.acceptStatusChange() }
        if (this.initialStatus === NOTE_STATUS.PROCESSED) { this.acceptStatusChange() }
        if (this.initialStatus === NOTE_STATUS.PROCESSING_COMPLETED) { this.acceptStatusChange() }
        break

      default:
        break
    }
  }

  // If the dropdown contains the "Mark note for archival" option, a confirmation is required and the Note CAN NOT be closed immediately.
  isUsingClosingConfirmation() {
    return Array.from(this.selectTarget.options).some((option) => option.value === NOTE_STATUS.MARKED_FOR_CLOSING)
  }

  acceptStatusChange() {
    switch (this.currentValue) {
      case NOTE_STATUS.PROCESSED:
      case NOTE_STATUS.PROCESSING_COMPLETED:
        this.submitForm()
        break
      case NOTE_STATUS.MARKED_FOR_CLOSING:
        showElement(this.markForClosingContainerTarget)
        break
      case NOTE_STATUS.CLOSED:
        showElement(this.immediateClosingContainerTarget)
        break
    }
  }

  hideConfirmationBoxes() {
    if (this.hasMarkForClosingContainerTarget) { hideElement(this.markForClosingContainerTarget) }
    if (this.hasImmediateClosingContainerTarget) { hideElement(this.immediateClosingContainerTarget) }
  }

  submitForm() {
    if (!this.hasHiddenInputTarget) { return }

    this.hiddenInputTarget.value = this.currentValue
    this.selectTarget.disabled = true
    this.hiddenInputTarget.closest("form").requestSubmit()
  }

  // Getters

  get currentValue() { return this.selectTarget.value }
}
