// IMPORTANT: Keep these in sync with the values defined in app/models/note.rb!
export const NOTE_STATUS_SUBMITTED = "submitted"
export const NOTE_STATUS_PROCESSED = "processed"
export const NOTE_STATUS_PROCESSING_COMPLETED = "processing_completed"
export const NOTE_STATUS_MARKED_FOR_CLOSING = "marked_for_closing"
export const NOTE_STATUS_CLOSED = "closed"
export const NOTE_STATUS_DELETED = "deleted"

export const NOTE_STATUS_MAP = {
  SUBMITTED: NOTE_STATUS_SUBMITTED,
  PROCESSED: NOTE_STATUS_PROCESSED,
  PROCESSING_COMPLETED: NOTE_STATUS_PROCESSING_COMPLETED,
  MARKED_FOR_CLOSING: NOTE_STATUS_MARKED_FOR_CLOSING,
  CLOSED: NOTE_STATUS_CLOSED,
  DELETED: NOTE_STATUS_DELETED
}
